@import '../styles/variables';

.login-page{
	max-width: 500px;
	padding: 3em;
	margin: 0 auto;
	border-radius: 0.5em;
	background: #fff;
	box-shadow: 1px 2px 24px rgba(0,0,0,0.4);
	.page-title{
		font-size: 1.5em;
		text-align: center;
	}
	#login-form .alert{
		margin: 0;
		ul{
			margin: 0;
			list-style: none;
			padding: 0;
		}
	}
	.form-group{
		margin-bottom: 0;
		padding: 1em 0;
	}
	.form-control{
		font-size: 1.3em;
		border-radius: 0;
	}
	label{
	    color: #a9a9a9;
	    font-weight: bold;
	    text-transform: uppercase;
	}
	.asteriskField{
		display: none;
	}
	.btn-submit{
		margin-top: 1em;
		font-size: 1.2em;
	}
}

#main-content{
	background: url("https://cdn.veloxwheel.com/pic/porsche-forged-1.jpg") no-repeat center center fixed;
	background-size: cover;
	min-height: 600px;
	height: 100vh;
	padding: 2em;
}
